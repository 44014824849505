import React, { FC } from 'react'
import Layout from '@/containers/Layout'
import SearchContainer from '@/containers/SearchContainer'
import { useGlobalStrings } from '@/lib/useGlobalStrings'
import { SeoSettings } from 'web/types/custom-graphql-types'

interface SearchPageProps {}

const SearchPage: FC<SearchPageProps> = (props) => {
  const i18n_base = {
    slug: {
      current: 'search'
    },
    _type: 'page',
    i18n_lang: 'en'
  }

  const { searchPageSeoTitle, searchPageSeoDescription } = useGlobalStrings()

  const seoSettings: SeoSettings = {
    seoTitle: searchPageSeoTitle?.de,
    seoDescription: searchPageSeoDescription?.de
  }

  return (
    <Layout i18n_lang="de" i18n_base={i18n_base} seoSettings={seoSettings}>
      <SearchContainer i18n_lang="de" />
    </Layout>
  )
}

export default SearchPage
